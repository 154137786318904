/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../Ihouse/layout/core'
import {DashCardOne} from '../../../Components/Cards/DashCardOne'
import DashBoardMain from './DashBoardMain'
import axios from '../../../Helpers/Api_instance'
import {useAuth} from '../../modules/auth'
import api_instance from '../../../Helpers/Api_instance'

const DashboardWrapper = () => {
  const intl = useIntl()
  const [bookings, setBookinngs] = useState(0)
  const [completed, setCompleted] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [orders, setOrders] = useState(0)

  const [data, setData] = useState({
    todaysOrders: 0,
    totalOrders: 0,
    todaysRevenue: 0,
    totalRevenue: 0,
  })

  const getDashBoardData = async () => {
    try {
      const res = await api_instance.get('/dashboard')

      if (res && res.data) {
        console.log('dashboard data', res.data)
        setData(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDashBoardData()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='calendar'
            color='primary'
            iconColor='white'
            title={data.todaysOrders}
            description='Todays Orders'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='check'
            color='warning'
            iconColor='white'
            title={data.todaysRevenue.toFixed(2) + '£'}
            description='Todays Revenue'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='handcart'
            color='danger'
            iconColor='white'
            title={data.totalOrders}
            description='Total Orders'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='dollar'
            color='success'
            iconColor='white'
            title={data?.totalRevenue?.toFixed(2) + '£'}
            description='Total Revenue'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
      </div>
      <DashBoardMain />
    </>
  )
}

export {DashboardWrapper}
