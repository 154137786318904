import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../Ihouse/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../Ihouse/assets/ts/_utils'
import {WithChildren} from '../../Ihouse/helpers'
import EditFoodForm from '../../Components/Forms/EditFoodForm'

const PrivateRoutes = () => {
  const Form = lazy(() => import('../../Components/Forms/Form1'))
  const FoodMenuTable = lazy(() => import('../../Components/Tables/FoodItemsTable'))
  const VouchersTable = lazy(() => import('../../Components/Tables/VouchersTable'))
  const TransactionTable = lazy(() => import('../../Components/Tables/TransactionTable'))
  const ContactTable = lazy(() => import('../../Components/Tables/ContactsTable'))
  const OrdersTable = lazy(() => import('../../Components/Tables/OrdersTable'))
  const TestimonialsTable = lazy(() => import('../../Components/Tables/TestimonialsTable'))
  const OrderPage = lazy(() => import('../../Components/OrderPage'))
  const ProfilePge = lazy(() => import('../../Components/Profile'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        <Route path='/EditFoodMenu/:id' element={<EditFoodForm />} />

        <Route
          path='/profile'
          element={
            <SuspensedView>
              <ProfilePge />
            </SuspensedView>
          }
        />

        <Route
          path='/foodmenu'
          element={
            <SuspensedView>
              <FoodMenuTable />
            </SuspensedView>
          }
        />
        <Route
          path='/addFoodmenu'
          element={
            <SuspensedView>
              <Form />
            </SuspensedView>
          }
        />
        <Route
          path='/vouchers'
          element={
            <SuspensedView>
              <VouchersTable />
            </SuspensedView>
          }
        />

        <Route
          path='/TransactionTable'
          element={
            <SuspensedView>
              <TransactionTable />
            </SuspensedView>
          }
        />
        <Route
          path='/contacts'
          element={
            <SuspensedView>
              <ContactTable />
            </SuspensedView>
          }
        />
        <Route
          path='/orders'
          element={
            <SuspensedView>
              <OrdersTable />
            </SuspensedView>
          }
        />


        <Route
          path='/order/:id'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
