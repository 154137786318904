/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import api_instance from '../../../../Helpers/Api_instance'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const otpSchema = Yup.object().shape({
  otp: Yup.string().length(4, 'OTP must be 4 digits').required('OTP is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showOtp, setShowOtp] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const res = await login(values.email, values.password)
        if (res && res.data) {
          setShowOtp(true)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const otpFormik = useFormik({
    initialValues: {otp: ''},
    validationSchema: otpSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const res = await api_instance.post('/auth/verify-otp', {
          otp: values.otp,
        })

        console.log('otp response', res.data)

        if (res && res.data) {
          try {
            const {data} = await getUserByToken()
            console.log('user', data)
            setCurrentUser(data.data)
          } catch (error) {
            console.log('error get user', error)
          }
        }
      } catch (error) {
        console.error(error)
        setStatus('Invalid OTP')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={showOtp ? otpFormik.handleSubmit : formik.handleSubmit}
      noValidate
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-5'>Hey Admin!</div>
      </div>

      {!showOtp ? (
        <>
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx('form-control bg-transparent', {
                'is-invalid': formik.touched.email && formik.errors.email,
                'is-valid': formik.touched.email && !formik.errors.email,
              })}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx('form-control bg-transparent', {
                'is-invalid': formik.touched.password && formik.errors.password,
                'is-valid': formik.touched.password && !formik.errors.password,
              })}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Enter OTP</label>
          <input
            type='text'
            placeholder='Enter OTP'
            autoComplete='off'
            {...otpFormik.getFieldProps('otp')}
            className={clsx('form-control bg-transparent', {
              'is-invalid': otpFormik.touched.otp && otpFormik.errors.otp,
              'is-valid': otpFormik.touched.otp && !otpFormik.errors.otp,
            })}
          />
          {otpFormik.touched.otp && otpFormik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{otpFormik.errors.otp}</span>
            </div>
          )}
        </div>
      )}

      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={
            (showOtp ? otpFormik.isSubmitting : formik.isSubmitting) ||
            !(showOtp ? otpFormik.isValid : formik.isValid)
          }
        >
          {!loading && (
            <span className='indicator-label'>{showOtp ? 'Verify OTP' : 'Continue'}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password?
        </Link>
      </div>
    </form>
  )
}
