import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Restaurant</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/foodmenu'
        title='Food Menu'
        icon='coffee'
        fontIcon='bi-person'
      ></SidebarMenuItem>

      <SidebarMenuItem
        to='/vouchers'
        title='Vouchers'
        icon='price-tag'
        fontIcon='bi-person'
      ></SidebarMenuItem>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Online</span>
        </div>
      </div>
      <SidebarMenuItem to='/Orders' icon='handcart' title='Orders' fontIcon='bi-chat-left' />
      <SidebarMenuItem
        to='/TransactionTable'
        icon='bill'
        title='Transaction'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem to='/contacts' icon='address-book' title='Contacts' fontIcon='bi-layers' />
    </>
  )
}

export {SidebarMenuMain}
