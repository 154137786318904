import {useEffect, useState} from 'react'
import axios from '../../Helpers/Api_instance'

type Props = {}

const statusColors: Record<string, string> = {
  'Order Created': 'badge-light-primary',
  Packed: 'badge-light-info',
  'Ready To Pick': 'badge-light-warning',
  'Out For Delivery': 'badge-light-dark',
  Completed: 'badge-light-success',
  Pending: 'badge-light-secondary',
  Accepted: 'badge-light-primary',
  Preparing: 'badge-light-danger',
}

function DashBoardTable({}: Props) {
  const [orders, setOrders] = useState<any[]>([])

  const getOrders = async () => {
    try {
      const res = await axios.get('/orders')
      if (res?.data) {
        setOrders(res.data.orders)
      }
    } catch (error: any) {
      console.error('Error fetching orders:', error.message)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])
  return (
    <div className='card mb-5 mb-xl-8'>
      {/*begin::Header*/}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Orders</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 orders</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Order Id</th>
                <th className='min-w-120px'>Date</th>
                <th className='min-w-140px'>User</th>
                <th className='min-w-120px'>Order Value</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {item.reference_number}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(item.createdAt).toLocaleDateString()}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Time: {new Date(item.createdAt).toLocaleTimeString()}
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.first_name + ' ' + item?.last_name}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {item?.mobile}
                      </span>
                    </td>
                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                      £{item?.transaction?.amount}
                    </td>

                    <td>
                      <span className={'badge ' + statusColors[item.status]}>{item.status}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <i className='ki-outline ki-eye fs-2' />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default DashBoardTable
