import * as React from 'react'
import {DashBoardTableTwo} from '../../../Components/Tables/DashBoardTableTwo'
import DashBoardTable from '../../../Components/Tables/DashBoardTable'

export interface IAppProps {}

export default function App(props: IAppProps) {
  return (
    <div className='row g-5 gx-xxl-8'>
      <div>
        {/* <DashBoardTableTwo className='card-xxl-stretch mb-5 mb-xxl-8' />*/}

        <DashBoardTable />
      </div>
    </div>
  )
}
