import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {toast} from 'react-toastify'
import axios from '../../../../Helpers/Api_instance'
import {useEffect, useState} from 'react'
const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
const Navbar = () => {
  const [store, setStore] = useState<any>({})
  const getStore = async () => {
    try {
      const res = await axios.get('/store')
      if (res && res.data) {
        setStore(res.data.data[0])
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item')}>
        <div className='form-check form-switch'></div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/logos/Admin_logo.png')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
