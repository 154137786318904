import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {toast} from 'react-toastify'
import api_instance from '../../../../Helpers/Api_instance'

const initialValues = {
  password: '',
  confirmPass: '',
}
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'password must be at least 6 characters')
    .required('password is required'),
  confirmPass: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm password is required'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()
  const {id} = useParams()
  const submitHandler = async (values, {resetForm}) => {
    try {
      setHasErrors(false)
      const res = await api_instance.post(`/auth/reset-forgot?token=${id}`, values)
      if (res && res.data) {
        setHasErrors(false)
        navigate('/auth/login')
      }
    } catch (error: any) {
      console.log(error)
      setHasErrors(true)
      resetForm()
      toast.error('Unable reset password !')
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={ResetPasswordSchema}
    >
      {() => (
        <Form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark fw-bolder mb-3'>Reset Password ?</h1>

            <div className='text-gray-500 fw-semibold fs-6'>
              Enter your password to reset your password.
            </div>
          </div>

          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                Your Password Reset is successfull, You can Login Now.
              </div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
            <Field
              type='password'
              placeholder='New Password'
              autoComplete='off'
              name='password'
              className={clsx('form-control bg-transparent')}
            />
            <ErrorMessage name='password' className='text-sm text-danger' />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'> Confirm password</label>
            <Field
              type='password'
              placeholder='Confirm Password'
              autoComplete='off'
              name='confirmPass'
              className={clsx('form-control bg-transparent')}
            />
            <ErrorMessage name='confirmPass' className='text-sm text-danger' />
          </div>

          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
              >
                Cancel
              </button>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}
